import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import AuthUser from "./AuthUser";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
	const navigate = useNavigate();
	//  token check
	const { getToken } = AuthUser();
	useEffect(() => {
		if (getToken()) {
			navigate("/dashboard");
		}
	}, [getToken, navigate]);

	const { http, setToken } = AuthUser();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const errorAlert = () => {
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: "Credentials are not correct",
		});
	};

	const onSubmit = (data) => {
		http
			.post("/auth/bill/login", {
				username: data.Username,
				password: data.Password,
			})
			.then((response) => {
				setToken(
					response.data.user,
					response.data.token,
					response.data.account_type,
					response.data.info
				);
			})
			.catch((error) => {
				errorAlert();
			});
	};

	return (
		<div>
			<div className="row m-0">
				<div className="col-12 p-0">
					<div className="login-card">
						<div>
							<div
								className="login-main"
								style={{
									textAlign: "center",
								}}
							>
								<img
									className=" company-logo"
									style={{ height: 100 }}
									alt="logo"
									src="/1689772513.png"
								/>
								<form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
									<h4 className="text-center"> </h4>
									<p className="text-center"></p>

									<div
										className="form-group"
										style={{
											textAlign: "initial",
										}}
									>
										<label>Username</label>
										<input
											className="form-control"
											type="text"
											{...register("Username", { required: true })}
											placeholder="Enter  Username"
										/>
										{errors.Username && (
											<span style={{ color: "red" }}>
												This field is required
											</span>
										)}
									</div>
									<div
										className="form-group"
										style={{
											textAlign: "initial",
										}}
									>
										<label className="col-form-label">Password</label>
										<div className="form-input position-relative">
											<input
												className="form-control"
												type="password"
												{...register("Password", { required: true })}
												placeholder="*********"
											/>
											{errors.Password && (
												<span style={{ color: "red" }}>
													This field is required
												</span>
											)}
										</div>
									</div>
									<div className="form-group mb-0">
										<div className="checkbox p-0">
											<input id="checkbox1" type="checkbox" />
										</div>

										<div className="text-end mt-3">
											<button
												className="btn btn-primary btn-block w-100"
												type="submit"
											>
												Sign in{" "}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
